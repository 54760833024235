import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME_TEAMS } from 'features/user/gql';
import _ from 'lodash';
import NonProTeams from '../../license-activation/NonProTeams';
import ChooseTeamList from './ChooseTeamList';
import LayoutHeader from 'components/LayoutHeader';
import styles from './ChooseTeam.module.scss';

function ChooseTeam() {
  const { data, loading } = useQuery(GET_ME_TEAMS);
  const [proTeams, nonProTeams] = _.partition(_.get(data, 'me.teams', []), (t) => t.hasAccess);
  return (
    <>
      <LayoutHeader helmetTitle="Choose team" title="Choose team" />
      {!loading && proTeams.length > 0 && <ChooseTeamList {...{ proTeams, loading }} />}
      {!loading && nonProTeams?.length > 0 && (
        <>
          <div className={[styles.header, styles.headerNonProTeams].join(' ')}>
            <h4>Non Pro Teams ({nonProTeams?.length ?? '0'})</h4>
          </div>
          <NonProTeams />
        </>
      )}
    </>
  );
}

export default ChooseTeam;
