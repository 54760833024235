import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME_TEAMS } from 'features/user/gql';
import _ from 'lodash';
import ChooseTeamList from './ChooseTeamList';

function ChooseTeam() {
  const { data, loading } = useQuery(GET_ME_TEAMS);
  const [proTeams, nonProTeams] = _.partition(_.get(data, 'me.teams', []), (t) => t.hasAccess);
  const numberOfNonProTeams = nonProTeams.length;
  return <ChooseTeamList {...{ proTeams, numberOfNonProTeams, loading }} />;
}

export default ChooseTeam;
