import React from 'react';
import { Link } from 'react-router-dom';
import ToggleMenu from 'components/ToggleMenu';
import ProfileImage from 'components/ProfileImage';
import useUser from 'hooks/useUser';
import styles from './UserMenu.module.scss';
import ListItem from '../ListItem';

function UserMenu() {
  const user = useUser();
  return (
    <div className={styles.wrapper}>
      <ListItem
        title={user?.fullName}
        right={
          <div className={styles.circleWrapper}>
            <ProfileImage {...{ user }} />
            <ToggleMenu
              menuItems={[
                { content: <Link to="/choose-team">My teams</Link> },
                { content: <Link to="/choose-team">Activate team</Link> },
                { content: <Link to="/signout">Sign out</Link> },
              ]}
            />
          </div>
        }
      />
    </div>
  );
}

export default UserMenu;
